<template>
  <div>
    <v-container>
      <p>
        What volume of
        <number-value :value="concAcid" unit="\text{M}" />
        HCl is required to neutralize
        <number-value :value="massBase" unit="\text{g}" /> of NaOH?
      </p>

      <calculation-input
        v-model="inputs.volume"
        prepend-text="$\text{Volume:}$"
        append-text="$\text{mL}$"
      />
    </v-container>
  </div>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import NumberValue from '@/tasks/components/NumberValue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';

export default {
  name: 'CalculateFinalVolumeTitrationHClNaOH',
  components: {CalculationInput, NumberValue},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        volume: null,
      },
    };
  },
  computed: {
    concAcid() {
      return this.taskState.getValueBySymbol('concAcid').content;
    },
    massBase() {
      return this.taskState.getValueBySymbol('massBase').content;
    },
  },
};
</script>
